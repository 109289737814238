import React from 'react'
import './skills.css'
import angular from '../../images/angular.png'
import { frontendSkills, backendSkills } from '../../data/skillsData'
function Skills() {

    return (

        <div className='section_wrapper'>

            <div className="heading_title d-flex justify-content-center">
                <div className="heading_chip ">
                    <h2>My Skills</h2>
                </div>
            </div>

            <div className="skill-content">
            
                <div className="row justify-content-around">

                    <div className="col-lg-5 col-sm-6 card-row">
                        <div className="card text-center mx-auto">
                            <h1>FrontEnd</h1>
                            <div className="skill-wrapper text-left">
                                {
                                    frontendSkills.map((skill,i) => {

                                        return (
                                            <div key={i} className="skill-chip">
                                                <div className="skill-inner" >
                                                <p>{skill.name}</p>
                                                <img src={skill.icon} alt="" />
                                                </div>
                                                
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                       
                    </div>

                    <div className="col-lg-5 col-sm-6 card-row">


                        <div className="card text-center mx-auto">
                            <h1>BackEnd</h1>
                            <div className="skill-wrapper text-left">
                                {
                                    backendSkills.map((skill) => {

                                        return (
                                            <div className="skill-chip">
                                                <div className="skill-inner" >
                                                <p>{skill.name}</p>
                                                <img src={skill.icon} alt="" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                       
                    </div>


                </div>
                


            </div>

        </div>


    )
}

export default Skills