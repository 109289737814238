import './App.css';
import Hero from './components/heroSection/Hero';
import NavBar from './components/navBar/NavBar';
import Skills from './components/skills/Skills';
import Timeline from './components/timeline/Timeline';

function App() {
  return (
    <div className="App container">
     <NavBar/>
      <Hero/>
     <Skills/>
     <Timeline/>
     
    </div>
  );
}

export default App;
