import React from 'react'
import './hero.css'
import Profile_Pic from "../../images/Profile-Pic.png"


function Hero() {
  return (
    <div>
      <div className="wrapper">
        <div className="About_section">
          
            <div className="row">

              <div className="col-lg-8 col-md-6 col-sm-12 text-left">

                <div className="About_card">
                  <h1>Hi,</h1>
                  <h1 className='Name'>I am <span>Jagadeesh Kumar</span></h1>
                  <h2>Full stack developer</h2>
                  <h2>Freelancer</h2>

                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ">
                <div className="Profile_container">
                  <img className='img-fluid mx-auto d-block profile' src={Profile_Pic} alt="" />
                </div>
              </div>

            </div>

         

        </div>

      </div>
    </div>
  )
}

export default Hero