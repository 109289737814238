import React from 'react'
import './timeline.css'
function Timeline() {
  return (

<section className="timeline-section">
	<div className="timeline-items">

		<div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2017</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
		<div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2018</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
		<div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2019</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
		<div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2020</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
		<div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2021</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
        <div className="timeline-item">
			<div className="timeline-dot"></div>
			<div className="timeline-date">2022</div>
			<div className="timeline-content">
				<h3>timeline item title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
			</div>
		</div>
	</div>
</section>

  )
}

export default Timeline