 export const frontendSkills = [
    {
        id: 1,
        name: "React",
        icon:"https://img.icons8.com/plasticine/24/000000/react.png"
    },
    {
        id: 2,
        name: "Angular",
        icon:"https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/000000/external-angular-a-typescript-based-open-source-web-application-framework-logo-color-tal-revivo.png"
    },
    {
        id: 3,
        name: "Typescript",
        icon:"https://img.icons8.com/color/24/000000/typescript.png"
    },
    {
        id: 4,
        name: "JQuery",
        icon:"https://img.icons8.com/ios/24/000000/jquery.png"
    },

    {
        id: 5,
        name: "Bootstrap",
        icon:"https://img.icons8.com/color/24/000000/bootstrap.png"
    },
    {
        id: 6,
        name: "TailWind",
        icon:"https://img.icons8.com/color/24/000000/tailwindcss.png"
    }
  
]

export const backendSkills = [
    {
        id: 1,
        name: "Node",
        icon:"https://img.icons8.com/fluency/24/000000/node-js.png"
    },
    {
        id: 2,
        name: "Express",
        icon:"https://img.icons8.com/fluency/24/000000/javascript.png"
    },
    {
        id: 3,
        name: "MySQL",
        icon:"https://img.icons8.com/office/24/000000/accept-database.png"
    },
    {
        id: 4,
        name: "MongoDb",
        icon:"https://img.icons8.com/color/24/000000/mongodb.png"
    },
    {
        id: 5,
        name: "php",
        icon:"https://img.icons8.com/offices/24/000000/php-logo.png"
    }

]
