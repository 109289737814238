import React from 'react'
import './navBar.css'
import logo from '../../images/logo.png'
import menu from '../../images/hamburger.png'


function NavBar() {
  return (
    <div>
         <header >
  <nav className="navbar navbar-dark  navbar-expand-lg ">
  <a className="navbar-brand" href="#">
    <h1 className='logo'>Jagadeesh.<span className='dev'>dev</span></h1>
    {/* <img src={logo} alt="Jagadeesh.dev" /> */}
  </a>

  

  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <img className="navbar-toggler-icon" src={menu} alt="Jagadeesh.dev" /> 
  </button>

  <div className="collapse navbar-collapse" id="navbarToggler">
    <ul className="navbar-nav ml-auto">
     
      <li className="nav-item active">
        <a className="nav-link" href="#">Home</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Blog</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Projects</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#">Contact</a>
      </li>
    </ul>
    
  </div>
</nav>
        
      </header>
    </div>
  )
}

export default NavBar